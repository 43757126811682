<template>
  <div class="item-menu" v-bind:class="classCols" data-toggle="tooltip" v-bind:title="item.description">
    <div class="inner">
      <div class="while-tooltip">
        <div class="menu-image  bg1 ">
          <img v-if="item.image" :src="getImage(item)" />
        </div>
        <div class="menu-name" style="z-index: 1">
          <b>{{ item.code }}</b>
        </div>
        <div class="menu-coupon-name" style="z-index: 1">
          <ellipsis :data="item.name"  :line-clamp="1"></ellipsis>
        </div>
        <div class="menu-coupon-name" style="z-index: 1">
          <ellipsis :data="item.content" :line-clamp="4"></ellipsis>
        </div>
        <div class="date-valid" style="z-index: 1">
          {{ $t('format.coupon.validate', {prefix: $t('coupon.label_expire'), datetime: getPrice(item)}) }}
        </div>
      </div>

      <div class="item-action flex pr-10 pl-10" style="flex-direction: column">
        <div style="width: 100%; text-align: right; line-height: 30px">
          <div class="btn btn-success btn-add-single-item " style="width: 100%;"
               v-on:click.stop="applyCoupon(item.code)">
            {{$t('button_redeem')}}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import vSelect from 'vue-select'
import Ellipsis from '@/components/partials/Ellipsis'
import moment from 'moment'

export default {
  props: ['item', 'cols', 'uuid'],
  components: {
    vSelect,
    Ellipsis
  },
  data () {
    return {
      classCols: 'cols-' + this.cols
    }
  },
  methods: {
    /**
     * apply coupon
     * */
    applyCoupon (code) {
      let self = this
      if (!this.checkPosition(this)) {
        this.$router.push({'name': 'home', query: {isShowWarning: 1, callback: this.$route.fullPath}})
      } else {
        let cart = this.$cart.get(this)
        let data = {cart_id: cart.uuid, coupon_code: code}
        this.$cart.applyCouponFromList(this, data)
      }
    },
    getImage (item) {
      let vm = this
      if (!item.image || vm.lodash.isEmpty(item.image)) {
        let lang = this.$i18n.locale || this.$const.LANG_ES.toUpperCase()
        if (!this.empty(lang)) {
          lang = lang.toUpperCase()
        } else {
          lang = this.$const.LANG_ES.toUpperCase()
        }
        if (vm.lodash.includes(this.$const.COUPON_TYPES.DISCOUNT, item.coupon_type_id)) {
          return this.$const.COUPON_IMAGES.DISCOUNT[lang]
        } else if (vm.lodash.includes(this.$const.COUPON_TYPES.FREE_GIFT, item.coupon_type_id)) {
          return this.$const.COUPON_IMAGES.FREE_GIFT[lang]
        } else {
          return this.$const.COUPON_IMAGES.PROMOTION[lang]
        }
      } else {
        return item.image.desktop
      }
    },
    getPrice (item) {
      let m = moment(item.expired_to)
      if (m.isValid() && m.year() >= 9999) {
        return this.$t('unlimited')
      } else {
        return item.expired_to
      }
    }
  }
}

</script>
<style scoped>
  @media (max-width: 990px) {
    .list-item-menu .item-menu.mode-view-list {
      width: calc(100% - 12px) !important;
      display: flex;
      flex-direction: row;
      padding-bottom: 0;
    }

    .item-menu .menu-coupon-name {
      font-size: 13px;
      /*font-weight: 300;*/
      padding: 10px 10px 6px;
    }
  }

  .list-item-menu .item-menu.mode-view-list {
    width: calc(100% - 12px);
    padding-bottom: 0;

  }

  .list-item-menu .item-menu.mode-view-list .menu-image {
    height: 100%;
    min-height: 160px;
  }

  .list-item-menu .item-menu.mode-view-list .flag-customise {
    height: 30px;
    width: 95%;
    background: hsla(0, 0%, 100%, .9);
    position: absolute;
    top: 30%;
    left: 0;
    text-align: center;
    border: 2px solid hsla(0, 0%, 100%, .9);
    border-radius: 25px;
  }

  .item-action {
    position: absolute;
    bottom: 0;
    width: 100%;
    margin-bottom: 10px;
  }

  @media only screen and (min-width: 960px) {
    .item-menu .menu-coupon-name {
      font-size: 13px;
      /*font-weight: 300;*/
      padding: 10px 10px 6px;
    }
  }

</style>
